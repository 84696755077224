import React, { useState } from 'react';
import shali from '../assests/shali.jpeg';
import ft from '../assests/ft.jpeg';
import article from '../assests/article.jpeg';
import arty from '../assests/arty.jpeg';
import faith from '../assests/faith.jpeg';
import glendora from '../assests/glendora.png';
import jha from "../assests/jha.png";
import CLUFT from "../assests/CLUFT.JPG";
import tourney from "../assests/tourney.jpg";
import ring from "../assests/ring.jpeg";
import bonita from "../assests/bonita.jpeg";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const Basketball = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const slides = [
    { src: shali, alt: 'Shalhevet', caption: 'High School Basketball Career', modalContent: 0 },
    { src: ft, alt: 'FT', caption: 'College Basketball Career', modalContent: 1 },
    { src: arty, alt: 'Faith & Basketball', caption: 'Articles About Faith & Basketball', modalContent: 2 } ,
  ];







  const modals = [
    (
      <div>
        <h2 className="text-2xl font-bold mb-4">Shalhevet Basketball</h2>
        <div>
          <a href="https://www.youtube.com/watch?v=zMRxlXw0PYw">
            <img className="w-[200px]" src={glendora} alt="Glendora" />
            <p>My High School Senior year highlights</p>
          </a>
          <a href="https://www.calhisports.com/2021/07/26/boys-bb-divisional-all-state-2021/">
            <img className="w-[200px]" src={jha} alt="JHA" />
            <p>CIF All State</p>
          </a>
          <a href="https://jewishjournal.com/news/338026/shalhevet-season-ends/">
            <img className="w-[200px]" src={bonita} alt="Bonita" />
            <p>Magical Season Comes To An End</p>
          </a>
        </div>
      </div>
    ),
    (
      <div>
        <h2 className="text-2xl font-bold mb-4">Cal Lutheran Basketball</h2>
        <div>
          <a href="https://www.youtube.com/watch?v=AUag-xsqnx0&feature=youtu.be">
            <img className="w-[200px]" src={CLUFT} alt="CLUFT" />
            <p>Cal Lutheran Freshman Year Highlights</p>
          </a>
          <a href="https://www.theacorn.com/articles/march-magic/">
            <img className="w-[200px]" src={tourney} alt="March Madness" />
            <p className="text-underline">March Madness</p>
          </a>
          <a href="https://www.theacorn.com/articles/purple-is-the-new-black/">
            <img className="w-[200px]" src={ring} alt="23-24" />
            <p className="text-underline">Sophomore Year Introduction</p>
          </a>
        </div>
      </div>
    ),
    (
      <div>
        <h2 className="text-2xl font-bold mb-4">Faith & Basketball</h2>
        <div>
          <a href="https://news.callutheran.edu/2023/10/keeping-the-faith/">
            <img className="w-[300px]" src={article} alt="Basketball" />
            <p>Cal Lutheran Magazine Article</p>
          </a>
          <a href="https://forward.com/news/sports/534678/zeev-remer-california-lutheran-basketball/">
            <img className="w-[300px]" src={faith} alt="Faith" />
            <p>Article from the Forward</p>
          </a>
        </div>
      </div>
    ),
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
    setModalOpen(false); // Close modal when changing slides
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    setModalOpen(false); // Close modal when changing slides
  };

  const openModal = () => {
    console.log("openModal called");
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="bg-black min-h-screen flex flex-col items-center justify-center">
      <div className="text-white text-3xl font-bold mb-1 md:text-5xl pt-10 text-center">
        My Basketball Career
      </div>
      <div
        className="relative w-full max-w-2xl"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img
          className="w-full h-auto max-h-[70vh] object-contain cursor-pointer"
          src={slides[currentSlide].src}
          alt={slides[currentSlide].alt}
          onClick={openModal}
        />
        {hovered && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-center text-yellow-500 text-sm sm:text-lg md:text-xl font-bold pointer-events-none">
            {slides[currentSlide].caption}
          </div>
        )}
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2"
          onClick={prevSlide}
        >
          <HiChevronLeft className="text-[#FCB825] font-bold" size={40} />
        </button>
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2"
          onClick={nextSlide}
        >
          <HiChevronRight className="text-[#FCB825] font-bold" size={40} />
        </button>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75 overflow-auto">
          <div className="bg-white p-8 rounded-md relative max-w-lg mx-auto">
            <button className="absolute top-2 right-2 text-xl" onClick={closeModal}>
              &times;
            </button>
            {modals[slides[currentSlide].modalContent]}
          </div>
        </div>
      )}
    </div>
  );
};

export default Basketball;
