import React from "react";
import zeevclu from "../assests/zeevclu.jpg";
import { GiGraduateCap } from "react-icons/gi";
import { SlCalender } from "react-icons/sl";
import { CiLocationArrow1 } from "react-icons/ci";
import { MdWorkOff } from "react-icons/md";
import remerrezzy from '../assests/remerrezzy.pdf';





const About = () => {
  return (
    <div id='about' className="w-full py-16 px-2 bg-[#31006F] flex items-center flex-col md:flex-row">
      <div className="max-w-[1200px] mx-auto md:w-2/5 md:h-2/5 mb-8 md:mb-0 sm:mb-0 sm:m-1-4 sm:h-1/4">
        <img
          className="w-full h-full object-cover mx-auto"
          src={zeevclu}
          alt="Ze'ev Remer"
        />
      </div>
      <div className="flex-grow flex flex-col items-center text-center mx-auto md:w-2/5 md:text-left mb-8 md:mb-0">
  <h1 className="text-[#FCB825] font-bold text-2xl md:text-4xl mb-2 mt-6 md:mx-auto text-center md:text-left w-full md:w-80 ">About Me</h1>
  <p className="text-white px-4 md:px-16 sm:pr-2 text-left ">
    Hi! I'm Ze'ev Remer, a student-athlete at California Lutheran University. Currently pursuing a degree in Computer Science with a minor in Economics, I have a deep passion for solving challenging problems and am always eager to learn new things. The field of Computer Science is particularly intriguing to me because I want to be a part of shaping the future.

    In addition to my academic pursuits, I take great pride in playing basketball for my university, embodying the spirit of a dedicated student-athlete. I love to compete on the court, leveraging teamwork and discipline to achieve success. It's not just about the game for me; it's a representation of hard work and perseverance.

    I value the relationships I have made along my journey. Building connections with others is important to me, and I cherish the friendships and networks that have enriched my life. The exchange of ideas and experiences is a powerful way to grow and broaden one's perspective.

    In my free time, you can find me engaging in various sports activities, exploring new places, or enjoying a good book. I'm always open to new challenges and opportunities for personal and professional development. Thanks for visiting my website, and feel free to reach out if you'd like to connect or learn more about my journey!
  </p>
        <div className="flex flex-col items-start mx-4 mt-4 md:mx-auto sm:pl-1 sm:w-full">
  <div className="flex items-center m-4">
    <GiGraduateCap className="text-[#FCB825]" size={30} />
    <p className="text-[#FCB825] font-bold ml-2">Education:</p>
    <p className="text-[#FCB825] ml-2 flex flex-col">Cal Lutheran University</p>
  </div>
  
  <div className="flex items-center p-4">
    <SlCalender className="text-[#FCB825]" size={30} />
    <p className="text-[#FCB825] font-bold ml-2">Age:</p>
    <p className="text-[#FCB825] ml-1">21</p>
  </div>

  <div className="flex items-center p-4">
    <CiLocationArrow1 className="mb-1 text-[#FCB825]" size={30} />
    <p className="text-[#FCB825] font-bold ml-2">Location: </p>
    <p className="text-[#FCB825] ml-2 flex flex-col">Los Angeles, CA</p>
    </div>
    <div className="flex items-center p-4">
    <MdWorkOff className="ml-2 mb-1 text-[#FCB825]" size={30} />
    <p className="text-[#FCB825] font-bold ml-2"> Employment: </p>
    <p className="text-[#FCB825] ml-2 flex flex-col">Searching</p>
  </div>

  <div className="p-4">
    <a href={remerrezzy} target="_blank" rel="Resume">
      <button className="bg-[#FCB825] text-[#31006F] font-bold ml-2 p-2 rounded-full">Resume</button>
    </a>
  </div>
</div>
      </div>
    </div>
  );
};

export default About;
