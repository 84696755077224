import React, { useState } from 'react';
import { FiDribbble } from "react-icons/fi";
import { MdMenu, MdOutlineClose } from "react-icons/md";
import logos from '../assests/logos.png';
import logod from '../assests/logod.png';

const Topbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };




  return (
    <div className={`bg-[#31006F] w-screen flex justify-between items-center h-24 text-white sticky top-0 z-50`}>
      {/* <FiDribbble size={40} /> */}

      {/* <h1 className='w-full text-3xl font-bold text-white m-4'>ZR2</h1> */}
      <a href='/#home'>
        <button>
      <img src={logos} alt='/' width='100' height='40'/>
      </button>
      </a>
      <ul className='hidden md:flex'>
        <a href='/#home'>
          <button>
            <li className='p-4 '>Home</li>
          </button>
        </a>
        <a href='/#about'>
          <button>
            <li className='p-4 '>About</li>
          </button>
        </a>
        <a href='/#basketball'>
          <button>
            <li className='p-4 '>Basketball</li>
          </button>
        </a>
        <a href='/#contact'>
          <button>
            <li className='p-4  '>Contact </li>
          </button>
        </a>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
        {!nav ? <MdMenu size={30} /> : <MdOutlineClose size={30} />}
      </div>

      <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-white ease-in-out duration-500' : 'fixed left-[-1000%] '}>
  {/* <FiDribbble className='border-black text-[#31006F]' size={40} /> */}
  <a href='/#home'>
    <button>
     {/* <h1 className='w-full text-3xl font-bold items-center text-[#31006F]'>ZR2 </h1> */}
     <img src={logod} alt='/' width='100' height='40'/>
     </button>
  </a>
 
  <ul className='p-24 uppercase'>
  <li className='p-4 border-b border-black text-[#31006F]'>
    <a href='/#home'>
      <button>Home</button>
    </a>
  </li>
  <li className='p-4 border-b border-black text-[#31006F]'>
    <a href='/#about'>
      <button>About Me</button>
    </a>
  </li>
  <li className='p-4 border-b border-black text-[#31006F]'>
    <a href='/#basketball'>
      <button>Basketball</button>
    </a>
  </li>
  <li className='p-4 border-b border-black text-[#31006F]'>
    <a href='/#contact'>
      <button>Contact</button>
    </a>
  </li>
</ul>

</div>


    </div>
  );
};

export default Topbar;
