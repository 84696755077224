import React,{useState} from 'react';
import Topbar from './components/Topbar';
import Home from './components/Home';
import About from './components/About';
import Basketball from './components/Basketball';
import Cont from './components/Cont';



function App() {


  return (
  <div>
   
  < Topbar/> 
  <Home/>
  <About/>
  <div id="basketball" className="section">
  <Basketball />
</div>  
<Cont/>
  </div>

  );

};

  export default App;