import {useRef} from "react";
import emailjs from '@emailjs/browser';
import { IoIosMail } from "react-icons/io";
import jeru from '../assests/jeru.jpg';








const Cont = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9sy2xlk', 'template_maupkj8', form.current, 'D7RVkp-fOKKc86Poe')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  return (
    <div
      id="contact"
      className="w-full min-h-screen text-black flex items-center justify-center"
      style={{
        backgroundImage: `url(${jeru})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat' ,
      }}
    >
      <div className="container mx-auto px-4 py-8 bg-white bg-opacity-75 rounded-lg shadow-md max-w-lg">
        <div className="flex flex-col items-center sm:flex-row sm:items-center sm:justify-center">
          <IoIosMail className="hidden sm:block text-[#FCB825] mb-1 mt-2 sm:mr-4" size={80} />
          <h2 className="text-[#FCB825] font-bold text-4xl mt-6 sm:mt-0 bg-[#31006F] sm:bg-transparent px-4 py-2">
            Contact Me
          </h2>
        </div>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="flex flex-col space-y-4 max-w-md mx-auto mt-6 sm:mt-8"
        >
          <input
            type="text"
            placeholder="Name"
            name="user_name"
            required
            className="p-2 border border-gray-300 rounded text-black"
          />
          <input
            type="email"
            placeholder="Email"
            name="user_email"
            required
            className="p-2 border border-gray-300 rounded text-black"
          />
          <input
            type="text"
            placeholder="Subject"
            name="subject"
            required
            className="p-2 border border-gray-300 rounded text-black"
          />
          <textarea
            name="message"
            placeholder="Message"
            cols="30"
            rows="10"
            className="p-2 border border-gray-300 rounded text-black"
          ></textarea>
          <button
            type="submit"
            className="bg-[#FCB825] text-[#31006F] font-bold rounded p-2 hover:bg-[#e6b823]"
          >
            Send Message
          </button>
        </form>
        <div className="flex flex-col items-center mt-4">
          <div className="flex items-center mb-4">
            <IoIosMail className="text-[#FCB825]" size={20} />
            <a
              href="mailto:zeev.remer@gmail.com"
              className="text-[#FCB825] bg-[#31006F] ml-2 font-bold text-xs px-2 py-1 rounded"
            >
              zeev.remer@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cont;
