import { useState, useEffect } from "react";
import { FiLinkedin, FiInstagram } from "react-icons/fi";
import { RiTwitterXFill } from "react-icons/ri";
import Typewriter from "typewriter-effect";
import { IoMdDocument } from "react-icons/io";
import remerrezzy from '../assests/remerrezzy.pdf';
import champs from '../assests/champs.jpeg';
import telly from '../assests/telly.jpeg';
import anthem from '../assests/anthem.jpg';
import jeru from '../assests/jeru.jpg';
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";








const Home = () => {
  const backgrounds = [champs, anthem, jeru];
  const [currentBackground, setCurrentBackground] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBackground((prevBackground) => (prevBackground + 1) % backgrounds.length);
    }, 8000); // Change image every 8 seconds

    return () => clearInterval(interval);
  }, [backgrounds.length]);

  const handlePrevClick = () => {
    setCurrentBackground((prevBackground) => (prevBackground - 1 + backgrounds.length) % backgrounds.length);
  };

  const handleNextClick = () => {
    setCurrentBackground((prevBackground) => (prevBackground + 1) % backgrounds.length);
  };

  return (
    <div 
      id='home' 
      className="bg-gray-200 h-screen flex items-center justify-center relative" 
      style={{ 
        backgroundImage: `url(${backgrounds[currentBackground]})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center' 
      }}
    >
      <div className="bg-[#31006F] p-16 rounded-md shadow-md w-2/3 flex flex-col items-center sm:w-4/5" style={{ backgroundColor: 'rgba(169, 169, 169, 0.5)' }}>
        <p className="text-[#FCB825] text-6xl font-bold mb-4">Ze'ev Remer</p>
        <div className="flex-grow-0 h-45 bg-gray-600 rounded-md "></div>
        <p className="p-4 text-white font-bold text-4xl ">
          <Typewriter 
            onInit={(typewriter) => {
              typewriter.typeString("Welcome to my website!")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I enjoy problem solving.")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I am a college basketball player.")
                .pauseFor(2000)
                .deleteAll()
                .typeString("I love the outdoors.")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Welcome to my website!")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I enjoy problem solving.")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I am a college basketball player.")
                .pauseFor(2000)
                .deleteAll()
                .typeString("I love the outdoors.")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Welcome to my website!")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I enjoy problem solving")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I am a college basketball player.")
                .pauseFor(2000)
                .deleteAll()
                .typeString("I love the outdoors.")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Welcome to my website!")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I enjoy problem solving")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I am a college basketball player.")
                .pauseFor(2000)
                .deleteAll()
                .typeString("I love the outdoors.")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Welcome to my website!")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I enjoy problem solving")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I am a college basketball player.")
                .pauseFor(2000)
                .deleteAll()
                .typeString("I love the outdoors.")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Welcome to my website!")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I enjoy problem solving")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I am a college basketball player.")
                .pauseFor(2000)
                .deleteAll()
                .typeString("I love the outdoors.")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Welcome to my website!")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I enjoy problem solving")
                .pauseFor(1000)
                .deleteAll()
                .typeString("I am a college basketball player.")
                .pauseFor(2000)
                .deleteAll()
                .typeString("I love the outdoors.")
                .pauseFor(1000)
                .deleteAll()
                .typeString("...")
          
              
                .start({ loop: true });
            }}
          />
        </p>
        <div className="text-white font-bold flex items-center justify-center ">
          <a href="https://www.linkedin.com/in/zremer" target="_blank" rel="LinkedIn">
            <button>
              <FiLinkedin size={50} className="mr-4 ml-4 text-[#FCB825]" />
            </button>
          </a>
          <a href="https://twitter.com/RemerZeev" target="_blank" rel="Twitter">
            <button>
              <RiTwitterXFill size={50} className=" text-[#FCB825] mr-4" />
            </button>
          </a>
          <a href="https://www.instagram.com/zeevremer/" target="_blank" rel="Instagram">
            <button>
              <FiInstagram size={50} className="mr-4 text-[#FCB825]" />
            </button>
          </a>
          <a href={remerrezzy} target="_blank" rel="Resume">
            <button>
              <IoMdDocument size={50} className="mr-4 text-[#FCB825]" />
            </button>
          </a>
        </div>
      </div>

      <button 
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full focus:outline-none" 
        onClick={handlePrevClick}
      >
        <HiChevronLeft size={40} />
      </button>
      <button 
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full focus:outline-none" 
        onClick={handleNextClick}
      >
        <HiChevronRight size={40} />
      </button>
    </div>
  );
};

export default Home;
